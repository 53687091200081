import React from "react";
import "./Konsultan.css";

const Konsultan = () => {
  return (
    <React.Fragment>
      <div className="container-konsultan">
        <img
        className="img-konsultan"
          src="https://edumatrix-indonesia.com/wp-content/uploads/2023/12/konsultan-polri2.jpeg"
          alt=""
        />
      </div>
    </React.Fragment>
  );
};

export default Konsultan;
